import React from "react";
import styled from 'styled-components'
import { Heading, Button } from "rebass";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: 999999;
  background: white;
`

const LoadingComponent = ({onClick}) => {
  return (
    <Wrapper>
      <Heading mb={4}>Update available!</Heading>
      <Button onClick={onClick}>Click to install</Button>
    </Wrapper>
  );
};

export default LoadingComponent;
