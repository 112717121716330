// example theme.js

export default {
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    black: "#000",
    blue: "#07c",
    white: "#fff",
    lightgray: "#f6f6ff",
    gray200: "#eeeeee",
    gray500: "#cccccc",
    gray800: "#828282",
    green: "#4BD292",
    rating4: "#a2e732",
    rating3: "#f7d828",
    rating2: "#ff9900",
    rating1: "red"
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  radii: {
    small: "5px"
  },
  fonts: {
    sans: "system-ui, sans-serif",
    mono: "Menlo, monospace"
  },
  shadows: {
    small: "1px 1px 15px rgba(0, 69, 119, 0.1)",
    large: "4px 16px 24px rgba(0, 0, 0, .125)"
  },
  maxWidthModal: "300px",
  buttons: {
    primary: {
      color: "#fff",
      backgroundColor: "#000"
    },
    outline: {
      color: "#000",
      backgroundColor: "transparent",
      boxShadow: "inset 0 0 0 2px"
    },
    delete: {
      color: "#fff",
      backgroundColor: "red",
    },
  }
};
