import React from "react";
import { Route, Redirect } from "react-router-dom";
import CheckAuth from "./CheckAuth";
import Loading from "../components/Loading/FullScreenLoading";

const PrivateRoute = ({
  component: Component,
  fallback: Fallback,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <CheckAuth>
        {({ data: { me }, loading }) => {
          if (loading) return <Loading />;
          // console.log({ me });
          return me ? (
            <Component {...props} />
          ) : Fallback ? (
            <Fallback {...props} />
          ) : (
            <Redirect to="/login" />
          );
        }}
      </CheckAuth>
    )}
  />
);

export default PrivateRoute;
