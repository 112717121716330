import { combineReducers } from 'redux';
import viewReducer from './viewReducer';
import { currentLocation, settingCurrentLocationError, settingCurrentLocation } from './userReducer';
import { routerReducer } from 'react-router-redux';


export default combineReducers({
  currentLocation, settingCurrentLocationError, settingCurrentLocation,
  viewOptions: viewReducer,
  routing: routerReducer
});
