export const TOGGLE_CARD_STYLE = "TOGGLE_CARD_STYLE";
export const OPEN_MODAL = "open_modal";
export const CLOSE_MODAL = "close_modal";
export const FETCH_VIEW_OPTIONS = "FETCH_VIEW_OPTIONS";
export const SET_LOCATION = "set_location";
export const SET_MAX_DISTANCE = "set_max_distance";
export const SET_MIN_RATING = "set_min_rating";
export const SET_CURRENT_LOCATION = "set_current_location";
export const SETTING_CURRENT_LOCATION = "setting_current_location";
export const SETTING_CURRENT_LOCATION_ERROR = "setting_current_location_error";
export const SETTING_CURRENT_LOCATION_SUCCESS = "setting_current_location_success";
