import React, { Fragment } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import Loadable from "react-loadable";
import { ThemeProvider } from "styled-components";

import { ToastContainer } from "react-toastify";
import Loading from "./components/Loading/FullScreenLoading";
import Login from "./pages/Login";
import PrivateRoute from "./utils/PrivateRoute";

import theme from "./theme";

// Various initializations
ReactGA.initialize("UA-114310032-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const Explore = Loadable({
  loader: () => import("./pages/Explore"),
  loading: Loading
});

const Dashboard = Loadable({
  loader: () => import("./pages/Dashboard"),
  loading: Loading
});

const DroolNew = Loadable({
  loader: () => import("./pages/DroolNew"),
  loading: Loading
});

const DroolEdit = Loadable({
  loader: () => import("./pages/DroolEdit"),
  loading: Loading
});

const DroolView = Loadable({
  loader: () => import("./pages/DroolView"),
  loading: Loading
});

const Profile = Loadable({
  loader: () => import("./pages/MyProfile"),
  loading: Loading
});

const UserProfile = Loadable({
  loader: () => import("./pages/UserProfile"),
  loading: Loading
});

const ProfileEdit = Loadable({
  loader: () => import("./pages/ProfileEdit"),
  loading: Loading
});

const TagView = Loadable({
  loader: () => import("./pages/TagView"),
  loading: Loading
});

const PlaceView = Loadable({
  loader: () => import("./pages/PlaceView"),
  loading: Loading
});

const DishView = Loadable({
  loader: () => import("./pages/DishView"),
  loading: Loading
});

const ListView = Loadable({
  loader: () => import("./pages/ListView"),
  loading: Loading
});

const UserLists = Loadable({
  loader: () => import("./pages/UserLists"),
  loading: Loading
});

const Landing = Loadable({
  loader: () => import("./pages/Landing"),
  loading: Loading
});

const Signup = Loadable({
  loader: () => import("./pages/Signup"),
  loading: Loading
});

const ForgotPassword = Loadable({
  loader: () => import("./pages/ForgotPassword"),
  loading: Loading
});

const ResetPassword = Loadable({
  loader: () => import("./pages/ResetPassword"),
  loading: Loading
});

const NotFound = Loadable({
  loader: () => import("./pages/NotFound"),
  loading: Loading
});

const OptionsModal = Loadable({
  loader: () => import("./components/drools/OptionsModal/OptionsModal"),
  loading: Loading
});

const App = props => {
  if (props.isLoading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={props.history}>
        <Fragment>
          <ToastContainer />
          <OptionsModal />
          <div className="ap-App_Wrapper">
            <div className="ap-App_Content">
              <Switch>
                <PrivateRoute
                  exact
                  path="/"
                  component={Dashboard}
                  fallback={Landing}
                />
                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute
                  exact
                  path="/profile/edit"
                  component={ProfileEdit}
                />
                <Route exact path="/user/:id" component={UserProfile} />
                <PrivateRoute exact path="/new" component={DroolNew} />
                <PrivateRoute
                  exact
                  path="/drools/edit/:id"
                  component={DroolEdit}
                />
                <Route exact path="/drools/:id" component={DroolView} />
                <Route path="/explore" component={Explore} />
                <Route exact path="/tag/:id" component={TagView} />
                <Route exact path="/place/:id" component={PlaceView} />
                <Route exact path="/dish/:id" component={DishView} />
                <Route exact path="/list/:id" component={ListView} />
                <Route exact path="/lists" component={UserLists} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/forgot" component={ForgotPassword} />
                <Route exact path="/reset/:token" component={ResetPassword} />
                <Route exact path="/login" component={Login} />
                <PrivateRoute
                  exact
                  path="/index.html"
                  component={Dashboard}
                  fallback={Landing}
                />
                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
        </Fragment>
      </ConnectedRouter>
    </ThemeProvider>
  );
};

export default App;
