import { SETTING_CURRENT_LOCATION_ERROR, SETTING_CURRENT_LOCATION_SUCCESS, SETTING_CURRENT_LOCATION } from '../actions/types';

export function settingCurrentLocationError(state = false, action) {
    switch (action.type) {
        case SETTING_CURRENT_LOCATION_ERROR:
            return action.hasErrored;

        default:
            return state;
    }
}

export function settingCurrentLocation(state = false, action) {
    switch (action.type) {
        case SETTING_CURRENT_LOCATION:
            return action.isLoading;

        default:
            return state;
    }
}

export function currentLocation(state = false, action) {
    switch (action.type) {
        case SETTING_CURRENT_LOCATION_SUCCESS:
            return action.currentLocation;

        default:
            return state;
    }
}
