import React from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

import DroolMockup from "../components/Landing/DroolMockup";

const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      _id
      token
    }
  }
`;

const Login = () => {
  let [currentState, setCurrentState] = React.useState({
    password: "",
    email: ""
  });
  let [redirect, setRedirect] = React.useState(false);

  const saveToState = e => {
    setCurrentState({ ...currentState, [e.target.name]: e.target.value });
  };

  if (redirect) {
    return <Redirect to={`/profile`} />;
  } else {
    return (
      <Mutation
        mutation={SIGNIN_MUTATION}
        refetchQueries={[`CURRENT_USER_QUERY`]}
        variables={currentState}>
        {(signin, { error, loading }) => {
          return (
            <div className="container lp-Landing_Wrapper">
              <div className="lp-Landing_TextWrapper">
                <h1 className="lp-Landing_IntroLogo">Drool</h1>
                <h3 className="lp-Landing_IntroHeadline">Welcome back!</h3>
                {error && error.message}
                <form
                  method="post"
                  onSubmit={async e => {
                    e.preventDefault();
                    try {
                      let result = await signin();
                      if (result.data.signin) {
                        localStorage.setItem("token", result.data.signin.token);
                        toast.success("Signed in!");
                        setRedirect(true);
                      } else {
                        toast.error("oops");
                      }
                    } catch (e) {
                      toast.error(e.message);
                    }
                  }}
                  className="lp-Landing_Form">
                  <div className="lp-Landing_InputFields">
                    <div className="form-group">
                      <label>E-Mail</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={currentState.email}
                        onChange={saveToState}
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={currentState.password}
                        onChange={saveToState}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="bt-Button bt-Button-secondary bt-Button_Full"
                    disabled={loading}>
                    {loading ? "Loading.." : "Login"}
                  </button>

                  <div className="lp-Landing_ActionWrapper">
                    <Link to="/signup" className="lp-Landing_Link">
                      Sign up
                    </Link>
                    <Link to="/forgot" className="lp-Landing_Link">
                      Forgot Password?
                    </Link>
                  </div>
                </form>
              </div>
              <DroolMockup />
            </div>
          );
        }}
      </Mutation>
    );
  }
};

export default Login;
