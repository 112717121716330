import {
  TOGGLE_CARD_STYLE,
  FETCH_VIEW_OPTIONS,
  SET_LOCATION,
  SET_MAX_DISTANCE,
  SET_MIN_RATING,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_CURRENT_LOCATION
} from "../actions/types";
import history from "../components/helpers/history";
import queryString from 'query-string';

const query = queryString.parse(history.location.search)

Object.keys(query).map((key, index) => {
  const value = query[key];
  return query[key] = value.split(",");
})

export default function(
  state = {
    cardStyle: "SHOW_LIST",
    modal: {
      visible: false
    },
    usingOwnLocation: false,
    maxDistance: Number(query.maxDistance) || 20,
    minRating: Number(query.minRating) || 4,
    location: query.location && [Number(query.location[0]), Number(query.location[1])],
    selectedCity: query.city || ""
  },
  action
) {
  switch (action.type) {
    case TOGGLE_CARD_STYLE:
      return {
        ...state,
        cardStyle: action.cardStyle
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.latlng,
        selectedCity: action.selectedCity,
        usingOwnLocation: action.usingOwnLocation
      };
    case SET_MAX_DISTANCE:
      return {
        ...state,
        maxDistance: action.maxDistance
      };
    case SET_MIN_RATING:
      return {
        ...state,
        minRating: action.minRating
    };
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.latlng
      };
    case OPEN_MODAL:
      return {
        ...state,
        modal: {
          visible: true
        }
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: {
          visible: false
        }
      };
    case FETCH_VIEW_OPTIONS:
      return state;
    default:
      return state;
  }
}
