import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";

const AUTH_QUERY = gql`
  query {
    me {
      _id
      displayName
      profilePicture
      location
      email
      permissions
    }
  }
`;

const CheckAuth = props => (
  <Query {...props} query={AUTH_QUERY} fetchPolicy="network-only">
    {payload => {
      return props.children(payload);
    }}
  </Query>
);

CheckAuth.propTypes = {
  children: PropTypes.func.isRequired
};

export default CheckAuth;
export { AUTH_QUERY };
