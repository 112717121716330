import React from "react";

import droolImg from "../../assets/images/drool-mockup.png";

const DroolMockup = () => {
  return (
    <div className="lp-Landing_ImageWrapper">
      <img
        src={droolImg}
        alt="Drool Food Discovery"
        className="lp-Landing_Image"
      />
    </div>
  );
};

export default DroolMockup;
