import React from "react";
import Dish from "../../assets/images/svg/Dish";

const LoadingComponent = () => {
  return (
    <div className="lo-Wrapper">
      <Dish className="lo-Wrapper_Icon" />
      Loading...
    </div>
  );
};

export default LoadingComponent;
