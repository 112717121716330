import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";

import { connectRouter, routerMiddleware } from "connected-react-router";
import history from "./components/helpers/history";
import * as serviceWorker from "./serviceWorker";
import initWorkboxRefresh from "@loopmode/cra-workbox-refresh";

import App from "./App";
import reducers from "./reducers";
import "./assets/stylesheets/style.scss";
import "materialize-css/dist/js/materialize.min.js";
import RefreshNotification from "./pages/RefreshNotification";

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://drool-graphql-prod.herokuapp.com"
      : process.env.NODE_ENV === "staging"
      ? "https://drool-graphql.herokuapp.com"
      : "http://localhost:4000/graphql"
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? token : ""
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  connectRouter(history)(reducers),
  /* initialState, */
  composeEnhancers(applyMiddleware(routerMiddleware(history), reduxThunk))
);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <React.Fragment>
        <App history={history} />
      </React.Fragment>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

function renderRefreshUI(registration, { refresh }) {
  const el = document.createElement("div");
  document.body.appendChild(el);

  ReactDOM.render(<RefreshNotification onClick={refresh} />, el);
}

serviceWorker.register({
  onUpdate: registration =>
    initWorkboxRefresh(registration, { render: renderRefreshUI })
});

// Notifications
// Somewhere in your app...

// function requestNotificationPermission() {
//   // Some browsers don't support Notification yet. I'm looking at you iOS Safari
//   if ("Notification" in window) {
//     if (
//       Notification.permission !== "denied" &&
//       Notification.permission !== "granted"
//     ) {
//       Notification.requestPermission();
//     }
//   }
// }
// and somewhere else in your app...

// if ("Notification" in window && Notification.permission === "granted") {
//   new Notification("You just got poked!");
// }
// No need to eject. For sure. If you want to integrate it into your service worker, use the src/serviceWorker.js and make modifications to it as needed.
